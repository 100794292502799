html, body, #root {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Exo', sans-serif;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

img {
  display: unset !important;
}

#root {
  overflow: hidden;
  & .uploadcare--widget__button_type_open {
    border: 1px solid;
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
    border-color: rgb(217, 217, 217);
    outline: none !important;

    &:hover {
      color: @primary-color;
      border-color: @primary-color;
    }
  }
}