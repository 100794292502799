.logo {
  @size: 32px;
  height: @size;
  width: @size;
  margin-right: 12px;
  fill: #095957;
}

.header {
  display: flex;
  background: rgb(144, 218, 215);
  justify-content: space-between;
}

.subHeader {
  color: black;
}

.logoContainer {
  font-weight: 700;
  color: #095957;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
  text-align: center;
  min-height: calc(100vh - 200px);
}

.footer {
  background: lightgrey;
}