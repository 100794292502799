.common {
  @apply text-primary;
}

.boxHeader {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;

  &:global(.large) {
    font-size: 17px;
  }
}

.boxLabel {
  font-weight: 400;
  font-style: normal;
  font-size: 12px;

  &:global(.large) {
    font-size: 14px;
  }
}

.pageHeader {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  margin-bottom: 22px;

  &:global(.small) {
    font-weight: 600;
    font-size: 17px;
  }

  &:global(.large) {
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
  }

  &:global(.very-large) {
    font-weight: 800;
    font-size: 60px;
    line-height: 120%;
  }
}

.pageSubheader {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 22px;

  &:global(.large) {
    font-size: 17px;
    line-height: 24px;
  }
}

.number {
  font-weight: 700;
  font-size: 19px;
  line-height: 20px;

  &:global(.large) {
    font-size: 21px;
  }

  &:global(.small) {
    font-size: 15px;
    font-weight: 600;
  }
}

.description {
  font-size: 14px;
}

.block {
  display: block;
}