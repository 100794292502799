.root {
  height: 50px;
  border-radius: 16px;
  padding: 8px 16px;
  @apply bg-success border-success !important;
  font-size: 15px;
  font-weight: 600;

  &:hover {
    @apply bg-success border-success !important;
  }

  @media (pointer: fine) {
    &:hover {
      background-color: #EB812DFF !important;
      border-color: #EB812DFF !important;
    }
  }
}

.withShadow {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.container {
  display: inline-block;
}

.block {
  width: 100% ;
}