@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import 'antd';

@import "@packages/components/styles/theme.less";
@import "@packages/components/styles/global.less";
@import "@packages/components/styles/antd.less";

html, body, #root {
  height: inherit !important;
}
