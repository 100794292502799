.root {
  height: 50px;
  border-radius: 16px;
  padding: 8px 16px;
  @apply border-primary text-primary;
  font-size: 15px;
  font-weight: 600;
}

.container {
  display: inline-block;
}

.block {
  width: 100% ;
}