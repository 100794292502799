.ant-empty-image img {
  margin: auto;
}

.anticon {
  vertical-align: baseline;
}

@media (max-width: theme('screens.sm')) {
  .ant-list-item-action {
  @apply ml-0 mt-3;
  }
}

.ant-drawer-body {
  @apply py-3;
}

.ant-btn-link {
  text-decoration: underline;
}

.ant-alert {
  white-space: pre-line;
  &.ant-alert-info {
    background-color: white;
  }
  & .ant-alert-icon {
    align-self: start;
    margin-top: 4px;
  }
}

.ant-empty {
  @apply pb-2;
}